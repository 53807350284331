import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const AppBannerTwo = () => {
  const data = useStaticQuery(graphql`
     query {
       lg: file(relativePath: { eq: "in-app/three-xl.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       md: file(relativePath: { eq: "in-app/three-md.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       sm: file(relativePath: { eq: "in-app/three-md.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
       xs: file(relativePath: { eq: "in-app/three-xs.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
       }
     }
  `);

  return (
    <BaseBanner data={data}/>
  );
};

export default AppBannerTwo;
